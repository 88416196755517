import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Option } from '@ptg-shared/controls/select/select.component';
import { EditMemberStatusHistoryComponent } from '../edit-member-status-history/edit-member-status-history.component';
import * as fromMember from '../../store/reducers';
import { AddressHistoryAction } from '../../store/actions';
import {
  MemberStatus,
  MetadataSection,
  StatusHistory,
} from '../../types/models';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { DateTime } from 'luxon';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';
import { InputAddressComponent } from '@ptg-shared/controls/input-address/input-address.component';
import { DatePipe } from '@angular/common';
import { InitiationProperty } from '@ptg-entity-management/services/models';
import { deepClone } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-edit-member-address-history',
  templateUrl: './edit-member-address-history.component.html',
  styleUrls: ['./edit-member-address-history.component.scss']
})
export class EditMemberAddressHistoryComponent implements OnInit {
  editForm = new FormGroup({
    addressType: new FormControl(),
    address: new FormGroup({
      street1: new FormControl(),
      street2: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      zipCode: new FormControl(),
      country: new FormControl(),
      effectFrom: new FormControl(),
      effectTo: new FormControl(),
    })
  });
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();
  listOptionAddressType: Option[] = [{displayValue: '', value: ''}];
  maxDate: Date = new Date();
  addressPropertySelected!: InitiationProperty;
  unsubscribeExist: any;

  readonly MY_DATE = MY_DATE;

  @ViewChild(InputAddressComponent) address!: InputAddressComponent; 

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityId: string;
      memberId: string;
      viewName: string;
      address: any;
      properties: InitiationProperty[];
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditMemberStatusHistoryComponent>,
    private memberStore: Store<fromMember.MemberState>
  ) {
    
  }

  ngOnInit(): void {
    this.setListOption(this.data?.properties ?? []);
    if (this.data.address) {
      this.setValueToFormGroup(this.data.address);
    }
  }

  setListOption(properties: InitiationProperty[]) {
    this.addressPropertySelected = deepClone(properties[0]);
    this.listOptionAddressType = properties.map(el => ({
      displayValue: el.entityPropertyName ?? '', value: el.entityPropertyId
    }))
  }

  setValueToFormGroup(data: any) {
    this.editForm.get('addressType')?.setValue(data?.entityPropertyId)
    this.editForm.get('address')?.patchValue(data);
  }

  onSubmit() {
    const datepipe = new DatePipe('en-US');
    const body = [{
      reCordId: this.data.memberId ?? null,
      entityId: this.data.entityId,
      key: this.editForm.value.addressType,
      entityPropertyId: this.editForm.value.addressType,
      effectFrom: datepipe.transform(this.editForm.value.address.effectFrom, 'yyyy-MM-dd'),
      effectTo: this.editForm?.value?.address?.effectTo ? datepipe.transform(this.editForm.value.address.effectTo, 'yyyy-MM-dd') : null,
      id: this.data?.address?.id ?? null
    }];
    this.memberStore.dispatch(
      AddressHistoryAction.checkExitAddress({ body })
    );
    if (!this.unsubscribeExist) {
      this.unsubscribeExist = this.memberStore
      .pipe(select(fromMember.selectAddressHistoryExitState))
      .subscribe((state) => {
        if(state?.messages) {
          if (state?.messages.length) {
            const form = this.editForm.get('address')?.get('effectFrom');
            if (form && this.addressPropertySelected) {
              const messageError = state?.messages[0].message;
              this.addressPropertySelected = {...this.addressPropertySelected, messageError}
              form.setErrors({inValidAsync:true});
            }
          } else {
            this.editForm.markAllAsTouched();
            if (this.editForm.valid) {
              this.saveValue();
            }
          }
          this.unsubscribeExist.unsubscribe();
          this.unsubscribeExist = undefined;
        }
      });
    }
  }

  saveValue() {
    const datepipe = new DatePipe('en-US');
    const editFormValue = this.editForm.value;
    editFormValue.address.effectFrom = datepipe.transform(editFormValue.address.effectFrom, 'yyyy-MM-dd');
    editFormValue.address.effectTo = editFormValue.address.effectTo
    ? datepipe.transform(editFormValue.address.effectTo, 'yyyy-MM-dd')
    : null;
    const result = {
      RecordId: this.data.memberId,
      EntityPropertyId: editFormValue.addressType,
      EntityId: this.data.entityId,
      ...editFormValue.address
    }

    if (this.data.address) {
      result.Id = this.data.address.id;
    }
    if (!['USA', 'CA', 'CAN'].includes(this.address.selectedCountryCode)) {
      result.stateName = result.state;
      if (!['USA', 'CA', 'CAN'].includes(this.addressPropertySelected?.options.find((item: any) => item.id === this.data.address.country)?.description)) {
        result.state = this.data.address.state;
      } else {
        result.state = null;
      }
    } else {
      result.stateName = null;
    }
    this.dialogRef.close(result);
  }

  onChangeStatusValue() {
    
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
