import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@ptg-shared/components';
import { showCancelDialog } from '@ptg-shared/utils/common.util';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { GetPaymentInfoService } from '../../services';
import { combineLatest } from 'rxjs';
import {
  AdjustmentDeduction,
  AdjustmentFundingSources,
  CreateAdjustmentDetailedQueryParams,
  CreateAdjustmentDetailedRequest,
  GetValidateAdjustmentDetailIdRequest,
  PaymentTab,
  SubType,
} from '../../services/models';
import { filter, takeUntil } from 'rxjs/operators';
import { PayStatus, PaymentInstructionType } from '../../types/enums';
import { DeductionType } from '@ptg-processing/features/payroll-calendar-container/types/enums';
import { MAX_VALUE_NUMBER, MIN_NEGATIVE_VALUE_NUMBER } from '@ptg-shared/constance';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';
import {
  PayeeDetailState,
  clearGetCreateAdjustmentDetailedStateAction,
  clearGetValidateAdjustmentDetailIdStateAction,
  getCreateAdjustmentDetailedAction,
  getValidateAdjustmentDetailIdAction,
} from '../../store';
import { Store } from '@ngrx/store';
import {
  createAdjustmentDetailedSelector,
  getValidateAdjustmentDetailIdSelector,
} from '../../store/selectors/new-detailed-adjustment.selector';
import { getDateFormatISO, getDateString } from '@ptg-shared/utils/string.util';
import { DeductionsItems } from '../../services/models/payment-deductions.model';
import { isOneTimePayment } from '../../types/constants/payment-info-tab.constant';
import { DateTime } from 'luxon';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';

@Component({
  selector: 'ptg-new-detailed-adjustment',
  templateUrl: './new-detailed-adjustment.component.html',
  styleUrls: ['./new-detailed-adjustment.component.scss'],
})
export class NewDetailedAdjustmentComponent extends BaseComponent {
  readonly InputType = InputType;
  formData: FormGroup = new FormGroup({});

  grossPayment = {
    asPaid: 0,
    netAmount: 0,
    adjustment: 0,
  };

  totalDeduction = {
    asPaid: 0,
    netAmount: 0,
    adjustment: 0,
  };

  netPayment = {
    asPaid: 0,
    netAmount: 0,
    adjustment: 0,
  };

  selectedRow?: PaymentTab;

  fundingSourceTitle: string = '';

  minAmount: number = MIN_NEGATIVE_VALUE_NUMBER;
  maxAmount: number = MAX_VALUE_NUMBER;

  isShowAdjustmentNullMessage: boolean = false;
  isShowAdjustmentZeroMessage: boolean = false;

  benefitPeriodStartDate: Date = MY_DATE.validMinDate;
  currentDate: Date = new Date();

  isShowClearAllButton: boolean = false;
  isOneTimePayment: boolean = false;
  isQdroDeduction: boolean = false;
  isDeductionAsFundingSources: boolean = false;

  readonly DeductionTypeEnum = DeductionType;

  isAdjustmentDeductionValid = true;

  constructor(
    private dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<NewDetailedAdjustmentComponent>,
    private readonly fb: FormBuilder,
    private getPaymentInfoService: GetPaymentInfoService,
    @Inject(MAT_DIALOG_DATA)
    public data: { selectedRow: PaymentTab; calculationType: CalculationType; payeeRecordId: string },
    private store: Store<PayeeDetailState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedRow = this.data.selectedRow;

    this.benefitPeriodStartDate = new Date(this.selectedRow.startPeriodDate ?? MY_DATE.validMinDate);

    this.isOneTimePayment = isOneTimePayment(this.selectedRow);
    this.getInitForm();

    this.getPaymentInfo();

    this.selectorValidateAdjustmentDetailId();
    this.selectorCreateAdjustmentDetailed();

    this.checkShowClearAllButton();
  }

  getInitForm() {
    this.formData = this.fb.group({
      accountingPostDate: this.fb.control(null, [this.dateRangeValidator()]),
      reason: [''],
      reversal: [false],
      fundingSourcesList: this.fb.array([]),
      deductionList: this.fb.array([]),
    });
  }

  get accountingPostDateControl(): FormControl {
    return this.formData?.get('accountingPostDate') as FormControl;
  }

  get reasonControl(): FormControl {
    return this.formData?.get('reason') as FormControl;
  }

  get reversalControl(): FormControl {
    return this.formData?.get('reversal') as FormControl;
  }
  // fundingSources or deductionAsFundingSources
  get fundingSourcesListControl(): FormArray {
    return this.formData?.get('fundingSourcesList') as FormArray;
  }

  get deductionListControl(): FormArray {
    return this.formData?.get('deductionList') as FormArray;
  }

  dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      const dateValue = DateTime.fromISO(control.value);
      const postedAt = getDateString(this.selectedRow?.postedAt ?? '');
      if (this.selectedRow?.postedAt && dateValue <= DateTime.fromISO(postedAt)) {
        return { errorMessageAccountingPostDate: 'Cannot choose posting date on or before the posted date of the selected payment.' };
      }

      return null;
    };
  }

  checkShowClearAllButton() {
    this.isShowClearAllButton =
      !this.reversalControl.value &&
      (this.fundingSourcesListControl.controls.some((item) => item.get('adjustment')?.value !== null) ||
        this.deductionListControl.controls.some((item) => item.get('adjustment')?.value !== null));
  }

  private getPaymentInfo() {
    combineLatest([
      this.getPaymentInfoService.getEarningInfo(),
      this.getPaymentInfoService.getDeductionInfo(),
      this.getPaymentInfoService.getDeductionAsFundingSource(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([earnings, deductions, deductionAsFundingSource]) => {
        this.isDeductionAsFundingSources = deductionAsFundingSource?.length > 0;
        if (!this.isDeductionAsFundingSources) {
          earnings.forEach((earning) => {
            const fundingSources = (earning.fundingSources ?? []).filter((item) => item.showInAddDetailAdjustment);
            const adjustmentFundingSources = (earning.adjustmentFundings ?? []).filter(
              (item) => item.showInAddDetailAdjustment,
            );
            if (!deductionAsFundingSource || !deductionAsFundingSource.length) {
              fundingSources.forEach((source) => {
                this.fundingSourcesListControl.push(
                  this.fb.group({
                    id: [source.id],
                    fundingSource: [source.name ?? ''],
                    asPaid: [source?.amount ?? 0],
                    netAmount: [source?.netAmount ?? 0],
                    adjustment: [null],
                    isNegativeError: [false],
                    earningItemId: [earning.id],
                    isInvalidAmount: [false],
                  }),
                );
              });
            }
            adjustmentFundingSources.forEach((source) => {
              this.fundingSourcesListControl.push(
                this.fb.group({
                  id: [source.id],
                  fundingSource: [source.name ?? ''],
                  asPaid: [source?.amount ?? 0],
                  netAmount: [source?.netAmount ?? 0],
                  adjustment: [null],
                  isNegativeError: [false],
                  mainFundingSourceId: [source.mainFundingSourceId],
                  earningItemId: [earning.id],
                  isInvalidAmount: [false],
                }),
              );
            });
          });
        }
        const deductionAsFundingSourceList = deductionAsFundingSource ?? [];
        deductionAsFundingSourceList.forEach((daf) => {
          const deductionSubTypes = daf.deductionSubTypes ?? [];
          deductionSubTypes.forEach((dst) => {
            this.fundingSourcesListControl.push(
              this.setItemDeductionAsFundingSource(dst, daf.deductionType as unknown as DeductionType),
            );
          });
          const courtOrderDeductions = daf.courtOrderDeductions ?? [];
          courtOrderDeductions.forEach((cod) => {
            this.fundingSourcesListControl.push(
              this.setItemDeductionAsFundingSource(cod, daf.deductionType as unknown as DeductionType, true),
            );
          });
        });

        this.isQdroDeduction = deductions.some((deduction) => deduction.deductionType === DeductionType.QDRO);
        const deductionsList = (deductions ?? []).filter((item) => item.showInAddDetailAdjustment);
        deductionsList.forEach((deduction) => {
          const isShowTooltip = this.shouldShowTooltip(deduction);
          this.deductionListControl.push(
            this.fb.group({
              deductionId: [deduction.deductionId],
              deductionSubTypeId: [deduction.deductionSubTypeId],
              labelDisplay: [deduction.labelDisplay ?? ''],
              asPaid: [deduction?.amount?.amountCurrent ?? 0],
              netAmount: [deduction?.amount?.amountNet ?? 0],
              adjustment: [null],
              isNegativeError: [false],
              caseNumber: [deduction.caseNumber],
              isInvalidAmount: [false],
              isShowTooltip: [isShowTooltip],
              deductionType: [deduction.deductionType],
              courtOrder: [deduction.courtOrder],
              payeeName: [deduction.payeeName],
              startDate: [deduction.startDate],
              endDate: [deduction.endDate],
              payrollPayeeAdjustmentDeductionId: [deduction.payrollPayeeAdjustmentDeductionId]
            }),
          );
        });

        this.setFundingSourceTitle();
        this.setTotalValue();
      });
  }

  setItemDeductionAsFundingSource(item: SubType, deductionType: DeductionType, isCourtOrder?: boolean) {
    const isShowTooltip = this.shouldShowTooltipDeductionAsFundingSource(deductionType);
    return this.fb.group({
      id: [item.subTypeId],
      fundingSource: [
        isCourtOrder ? item.caseNumber : `${(item.deductionCode ?? '') + ' - ' + (item.subTypeName ?? '')}`,
      ],
      asPaid: [item?.amount ?? 0],
      netAmount: [item?.netAmount ?? 0],
      adjustment: [null],
      isNegativeError: [false],
      payrollDeductionId: [item.payrollDeductionId],
      isInvalidAmount: [false],
      caseNumber: [item.caseNumber ?? null],
      payeeName: [item.payee],
      deductionType: [deductionType],
      isShowTooltip: [isShowTooltip],
    });
  }

  shouldShowTooltipDeductionAsFundingSource(deductionType: DeductionType) {
    return deductionType === DeductionType.Garnishment || deductionType === DeductionType.QDRO;
  }

  shouldShowTooltip(deductionsItem: DeductionsItems): boolean {
    if (!deductionsItem.deductionType) {
      return false;
    }

    if (this.selectedRow?.payStatus !== PayStatus.Finalized) {
      if( deductionsItem.deductionType === DeductionType.Adjustment ){
        return (
          this.selectedRow?.paymentType === PaymentInstructionType.AllRecurring ||
          this.selectedRow?.paymentType === PaymentInstructionType.Recurring
        );
      }
      if (this.isOneTimePayment) {
        return (
          deductionsItem.deductionType === DeductionType.Garnishment || deductionsItem.deductionType === DeductionType.QDRO
        );
      }
      return deductionsItem.deductionType !== DeductionType.Tax;
    }

    return (
      deductionsItem.deductionType === DeductionType.Garnishment || deductionsItem.deductionType === DeductionType.QDRO
    );
  }

  private calculationTotal(controls: AbstractControl[], field: string) {
    return controls.reduce((prev, curr) => prev + (curr.get(field)?.value ?? 0), 0);
  }

  private setTotalValue() {
    this.grossPayment = {
      asPaid: this.calculationTotal(this.fundingSourcesListControl.controls, 'asPaid'),
      netAmount: this.calculationTotal(this.fundingSourcesListControl.controls, 'netAmount'),
      adjustment: this.calculationTotal(this.fundingSourcesListControl.controls, 'adjustment'),
    };

    this.totalDeduction = {
      asPaid: this.calculationTotal(this.deductionListControl.controls, 'asPaid'),
      netAmount: this.calculationTotal(this.deductionListControl.controls, 'netAmount'),
      adjustment: this.calculationTotal(this.deductionListControl.controls, 'adjustment'),
    };

    this.netPayment = {
      asPaid: this.grossPayment.asPaid - this.totalDeduction.asPaid,
      netAmount: this.grossPayment.netAmount - this.totalDeduction.netAmount,
      adjustment: this.grossPayment.adjustment - this.totalDeduction.adjustment,
    };
  }

  private setFundingSourceTitle() {
    if (this.isDeductionAsFundingSources) {
      this.fundingSourceTitle = 'Deduction as Funding Source';
      return;
    }

    this.fundingSourceTitle = this.fundingSourcesListControl.controls.length > 1 ? 'Funding Sources' : 'Earnings';
  }

  private calculationNetPayment() {
    this.netPayment = {
      asPaid: this.grossPayment.asPaid - this.totalDeduction.asPaid,
      netAmount: this.grossPayment.netAmount - this.totalDeduction.netAmount,
      adjustment: this.grossPayment.adjustment - this.totalDeduction.adjustment,
    };
  }

  onChangeFundingAmount(i: number) {
    this.fundingSourcesListControl.controls[i].get('isNegativeError')?.setValue(false);

    const isInvalidAmount =
      this.fundingSourcesListControl.controls[i].get('adjustment')?.value < this.minAmount ||
      this.fundingSourcesListControl.controls[i].get('adjustment')?.value > this.maxAmount;

    this.fundingSourcesListControl.controls[i].get('isInvalidAmount')?.setValue(isInvalidAmount);

    this.grossPayment = {
      ...this.grossPayment,
      adjustment: this.fundingSourcesListControl.controls.reduce(
        (prev, curr) => prev + (curr.get('adjustment')?.value ?? 0),
        0,
      ),
    };

    this.calculationNetPayment();

    this.isShowAdjustmentNullMessage = false;
    this.isShowAdjustmentZeroMessage = false;

    this.checkShowClearAllButton();
  }

  checkAdjustmentDeductionValid() {
    let deduction = this.totalDeduction.adjustment + this.totalDeduction.netAmount;
    deduction = Number(deduction.toFixed(2));
    if (!isNaN(deduction)) {
      this.isAdjustmentDeductionValid = deduction <= Number(this.grossPayment.asPaid.toFixed(2));
    }
  }

  onChangeDeductionAmount(i: number) {
    this.deductionListControl.controls[i].get('isNegativeError')?.setValue(false);
    const isInvalidAmount =
      this.deductionListControl.controls[i].get('adjustment')?.value < this.minAmount ||
      this.deductionListControl.controls[i].get('adjustment')?.value > this.maxAmount;

    this.deductionListControl.controls[i].get('isInvalidAmount')?.setValue(isInvalidAmount);

    this.totalDeduction = {
      ...this.totalDeduction,
      adjustment: this.deductionListControl.controls.reduce(
        (prev, curr) => prev + (curr.get('adjustment')?.value ?? 0),
        0,
      ),
    };

    this.calculationNetPayment();

    this.isShowAdjustmentNullMessage = false;
    this.isShowAdjustmentZeroMessage = false;

    this.checkShowClearAllButton();
    setTimeout(() => this.checkAdjustmentDeductionValid(), 100)
  }

  onReversal(event: boolean) {
    this.fundingSourcesListControl.controls.forEach((control) => {
      control.get('adjustment')?.setValue(-1 * control.get('netAmount')?.value);
    });

    this.deductionListControl.controls.forEach((control) => {
      control.get('adjustment')?.setValue(-1 * control.get('netAmount')?.value);
    });

    this.checkShowClearAllButton();
  }

  onSave() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Confirmation',
        text: 'Do you want to adjust the selected payment?',
        type: ConfirmType.ConfirmSave,
        saveButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
        hideSaveAsButton: true,
        hideConfirmButton: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const isFormValid = this.validateBeforeSubmit();
        if (!isFormValid) return;
        if (
          (this.data.calculationType === CalculationType.RetirementBenefit ||
            this.data.calculationType === CalculationType.Reduced) &&
          !this.isOneTimePayment &&
          this.isQdroDeduction
        ) {
          const request: GetValidateAdjustmentDetailIdRequest = {
            calculationType: CalculationType.RetirementBenefit,
            paymentInstructionHistoryId: this.selectedRow?.paymentInstructionHistoryId,
            benefitStartDate: this.selectedRow?.startPeriodDate
              ? getDateFormatISO(this.selectedRow?.startPeriodDate).toString()
              : '',
            benefitEndDate: this.selectedRow?.endPeriodDate
              ? getDateFormatISO(this.selectedRow?.endPeriodDate).toString()
              : '',
          };
          this.store.dispatch(getValidateAdjustmentDetailIdAction({ request }));

          return;
        }

        this.submitData();
      }
    });
  }

  private submitData() {
    const adjustmentDeduction: AdjustmentDeduction[] = this.deductionListControl.controls.map((control) => ({
      deductionId: control.get('deductionId')?.value ?? '',
      deductionSubType: control.get('deductionSubTypeId')?.value ?? '',
      adjustmentDeductionAmount: control.get('adjustment')?.value ?? 0,
      caseNumber: control.get('caseNumber')?.value ?? '',
      payrollPayeeAdjustmentDeductionId: control.get('payrollPayeeAdjustmentDeductionId')?.value ?? null,
    }));

    const adjustmentFundingSource: AdjustmentFundingSources[] = this.fundingSourcesListControl.controls.map(
      (control) => ({
        fundingSourceId: control.get('id')?.value ?? '',
        mainFundingSourceId: control.get('mainFundingSourceId')?.value ?? '',
        earningItemId: control.get('earningItemId')?.value ?? '',
        fundingSourceAmount: control.get('adjustment')?.value ?? 0,
      }),
    );

    const deductionAsFundingSource: AdjustmentDeduction[] = this.fundingSourcesListControl.controls.map((control) => ({
      deductionId: control.get('payrollDeductionId')?.value ?? '',
      deductionSubType: control.get('id')?.value ?? '',
      adjustmentDeductionAmount: control.get('adjustment')?.value ?? 0,
      caseNumber: control.get('caseNumber')?.value ?? null,
    }));

    const request: CreateAdjustmentDetailedRequest = {
      paymentInstructionHistoryId: this.selectedRow?.paymentInstructionHistoryId ?? '',
      payeeEntityRecordId: this.data.payeeRecordId,
      postingDate: getDateFormatISO(this.accountingPostDateControl.value).toString(),
      reason: this.reasonControl.value,
      benefitStartDate: this.selectedRow?.startPeriodDate
        ? getDateFormatISO(this.selectedRow?.startPeriodDate).toString()
        : '',
      benefitEndDate: this.selectedRow?.endPeriodDate
        ? getDateFormatISO(this.selectedRow?.endPeriodDate).toString()
        : '',
      adjustmentDeduction: adjustmentDeduction,
      adjustmentFundingSource: this.isDeductionAsFundingSources ? [] : adjustmentFundingSource,
      deductionAsFundingSource: !this.isDeductionAsFundingSources ? [] : deductionAsFundingSource,
    };
    const queries: CreateAdjustmentDetailedQueryParams = {
      benefitEntityId: this.data.selectedRow.benefitEntityId ?? '',
      benefitSubType: this.data.selectedRow.benefitSubtype ?? '',
    };

    this.store.dispatch(getCreateAdjustmentDetailedAction({ request, queries }));
  }

  private selectorValidateAdjustmentDetailId() {
    this.store
      .select(getValidateAdjustmentDetailIdSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.store.dispatch(clearGetValidateAdjustmentDetailIdStateAction());
        const isAdjusted = state?.payload?.isAdjusted ?? false;
        if (!isAdjusted) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              title: 'Error',
              text: 'Please check the corresponding payment of the alternate Payee before adjusting the selected payment of the main Payee',
              type: ConfirmType.Warning,
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            },
          });
          return;
        }

        this.submitData();
      });
  }

  private selectorCreateAdjustmentDetailed() {
    this.store
      .select(createAdjustmentDetailedSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.store.dispatch(clearGetCreateAdjustmentDetailedStateAction());
        this.dialogRef.close({ resultStatus: state?.success, adjustmentId: state?.payload });
      });
  }

  private validateBeforeSubmit() {
    this.formData.markAllAsTouched();
    const isAllAdjustmentNull =
      this.fundingSourcesListControl.controls.every((control) => control.get('adjustment')?.value === null) &&
      this.deductionListControl.controls.every((control) => control.get('adjustment')?.value === null);

    const isAllAdjustmentZero =
      this.fundingSourcesListControl.controls.every((control) => !control.get('adjustment')?.value) &&
      this.deductionListControl.controls.every((control) => !control.get('adjustment')?.value);

    const isInvalidAmount =
      this.fundingSourcesListControl.controls.every((control) => control.get('isInvalidAmount')?.value) ||
      (this.deductionListControl.controls.length &&
        this.deductionListControl.controls.every((control) => control.get('isInvalidAmount')?.value));

    if (isAllAdjustmentNull) {
      this.isShowAdjustmentNullMessage = true;
      return false;
    }

    if (isAllAdjustmentZero) {
      this.isShowAdjustmentZeroMessage = true;
      return false;
    }

    if (isInvalidAmount) {
      return false;
    }

    [...this.fundingSourcesListControl.controls, ...this.deductionListControl.controls].forEach((control) => {
      const isNegativeItem = control.get('adjustment')?.value + control.get('netAmount')?.value < 0;
      if (isNegativeItem) {
        control.get('isNegativeError')?.setValue(true);
        return;
      }
      control.get('isNegativeError')?.setValue(false);
    });

    const isNegativeError =
      this.fundingSourcesListControl.controls.some((control) => control.get('isNegativeError')?.value) ||
      this.deductionListControl.controls.some((control) => control.get('isNegativeError')?.value);

    if (isNegativeError) {
      return false;
    }

    if (this.formData.invalid) {
      return false;
    }

    return true;
  }

  onClearAll() {
    this.fundingSourcesListControl.controls.forEach((control) => {
      control.get('adjustment')?.reset();
    });

    this.deductionListControl.controls.forEach((control) => {
      control.get('adjustment')?.reset();
    });
  }

  onCancel() {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  identify(index: number): number {
    return index;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
