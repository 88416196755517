import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { EmployerService } from '@ptg-employer/services/employer.service';
import * as FundListActions from '@ptg-fund-list/actions/fund-list.actions';

import { FundModel, Module } from '@ptg-fund-list/models/fund-list.model';
import { ResourceCenterService } from '@ptg-fund-management/services/resource-center.service';
import { MemberListActions } from '@ptg-member/store/actions';
import * as fromMember from '@ptg-member/store/reducers';
import { MemberDetailService } from '@ptg-member/services/member-detail.service';
import { ReportCategorySidebar, ReportSidebar } from '@ptg-member/types/models/participant-report.model';
import * as fromReducer from '@ptg-reducers/index';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { BaseComponent } from '@ptg-shared/components';
import { PERMISSION_KEY } from '@ptg-shared/constance/permission.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { LayoutActions } from '@ptg-shared/layout/actions';
import {
  DEFAULT_TAB,
  EMPLOYER_PORTAL_DEFAULT_TAB,
} from '@ptg-shared/layout/components/captionbar/types/caption-bar.const';
import { Tab } from '@ptg-shared/layout/components/captionbar/types/caption-bar.model';
import { DEFAULT_EMPLOYER_PORTAL_TAB_MENU_GROUPS } from '@ptg-shared/layout/constance/employer-portal-menu.const';
import {
  DEFAULT_TAB_URL,
  DisplayedTabName,
  HiddenTabName,
  INDIVIDUAL_EMPLOYER_URLS,
  MUNICIPALITY_PARTICIPANTS_MENU_TITLE,
  OVERVIEW_URLS,
  SHOULD_NOT_REDIRECT_TO_EMPLOYER_TAB_URL_LIST,
  SidebarOpenedStatus,
  STRING_QUERY_PARAM,
  TAB_PERMISSION,
  TAB_URLS,
} from '@ptg-shared/layout/constance/layout.const';
import {
  BVFF_EMPLOYER_OVERVIEW_MENU_LIST,
  BVFF_EMPLOYERS_REPORT_MENU_GROUP,
  BVFF_EMPLOYERS_SETTING_MENU_GROUP,
  DEFAULT_TAB_MENU_GROUPS,
  EMPLOYER_OVERVIEW_MENU_LIST,
  MEMBER_OVERVIEW_MENU_LIST,
  BVFF_EMPLOYER_OVERVIEW_REPORT_PENSION_MENU,
  EMPLOYER_DOCUMENT_MENU_GROUP
} from '@ptg-shared/layout/constance/menu.const';
import { Menu, MenuGroup, PaymentInfoParams, TabName } from '@ptg-shared/layout/models/layout.model';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { PermissionModel } from '@ptg-shared/types/models/permission.model';
import { CheckPermissionService } from '@ptg-shared/services/check-permission.service';
import { HandleErrorService } from '@ptg-shared/services/handle-error.service';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FundType } from '@ptg-shared/types/enums';
import { deepClone, deepEqual, deepFlattenArray, getCurrentFundData } from '@ptg-shared/utils/common.util';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';
import { isEmpty } from '@ptg-shared/utils/string.util';
import { CheckVersionFlagService } from '@ptg-shared/services/check-version-flag.service';
import { ReportCategoryType } from '@ptg-employer/constance/report.const';
import {
  CalculationState,
  getCalculationQDROSelector,
  getQDROLabelNameAction,
} from '@ptg-member/features/calculation/store';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';

@Component({
  selector: 'ptg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent {
  readonly SIDE_BAR_OPENED_STATUS_ENUM = SidebarOpenedStatus;
  unsubscribe$: Subject<void> = new Subject<void>();
  title = 'pension-admin';
  isLogin = false;
  isDisabledMenu = false;
  paymentInfoParams!: PaymentInfoParams;
  sidebarOpenedStatus: SidebarOpenedStatus = SidebarOpenedStatus.Opened;
  tabName: TabName = HiddenTabName.Hidden;
  tabs: Tab[] = this.defaultTabs;
  menuGroups: MenuGroup[] = [];
  allMenuGroups: Partial<Record<DisplayedTabName, MenuGroup[]>> = this.defaultTabMenuGroups;
  disabledSideBar: boolean = false;
  currentFund!: FundModel;
  isMember?: boolean = false;
  qdroLabelName: string = CalculationType[CalculationType.QDRO];
  currentEntityProfile: string = '';
  loadingCustomClass = 'absolute h-full w-full';
  forceReloadMenu: boolean = false;
  @ViewChild('mainContent') mainContent!: ElementRef;
  @ViewChild('globalLoadingIndicator') globalLoadingIndicator!: ElementRef;

  get defaultTabs() {
    return deepClone(this.authService.isAdminPortal$.value ? DEFAULT_TAB : EMPLOYER_PORTAL_DEFAULT_TAB);
  }
  get defaultTabMenuGroups() {
    return deepClone(
      this.authService.isAdminPortal$.value ? DEFAULT_TAB_MENU_GROUPS : DEFAULT_EMPLOYER_PORTAL_TAB_MENU_GROUPS,
    );
  }

  get topPosition() {
    return `${(this.mainContent?.nativeElement as HTMLElement)?.scrollTop ?? 0}px`;
  }

  constructor(
    private authService: Auth0Service,
    public router: Router,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    private calculationStore: Store<CalculationState>,
    public checkPermissionService: CheckPermissionService,
    public memberDetailService: MemberDetailService,
    private handleErrorService: HandleErrorService,
    private memberStore: Store<fromMember.MemberState>,
    private renderer: Renderer2,
    public layoutService: LayoutService,
    private resourceCenterService: ResourceCenterService,
    private checkVersionFlagService: CheckVersionFlagService,
    private employerService: EmployerService,
    private cookieService: CookieService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkAuthenticationState();
    this.loadAllDynamicMenu();
    this.getAllMenuGroups();
    this.checkSelectedTab();
    this.checkSidebarState();
    this.checkNavigationEnd();
    this.checkCommonError();
    this.checkFundChange();
    this.getQDROLabelName();
    this.checkOpenedDialog();
  }

  logout() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Log Out',
        text: 'Are you sure you want to log out?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cookieService.delete('version');
        this.authService.logout();
      }
    });
  }

  checkCommonError() {
    this.store
      .select(fromLayoutReducer.selectInterceptorState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.showErrorDialog && state.error) {
          this.handleErrorService.showError$.next(state.error);
        }
      });
  }

  changeSelectedTab(event: any) {
    this.layoutService.showLoading = true;
    this.store.dispatch(LayoutActions.selectTab({ tab: event?.tabName, url: event?.url }));
    void this.router.navigateByUrl(
      event?.tabName === 'individualParticipant'
      || event?.tabName === 'searchedParticipant'
      || event?.tabName === 'individualEmployer'
        ? event?.url
        : DEFAULT_TAB_URL[event?.tabName as DisplayedTabName],
    );
  }

  checkAuthenticationState() {
    // this.authService.initializeAuth();
    this.authService.isAuthenticated$.pipe(takeUntil(this.unsubscribe$)).subscribe((isAuthenticated: boolean) => {
      this.isLogin = isAuthenticated;
      this.setDefaultLayoutValue();
      this.reloadCurrentFund();
      if (!this.isLogin) {
        localStorage.clear();
        location.reload();
      }
    });
  }

  checkSidebarState() {
    this.store
      .select(fromLayoutReducer.selectSidebarOpenedStatusState)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((sidebarOpenedStatusState) => {
        this.sidebarOpenedStatus = sidebarOpenedStatusState;
        this.controlSideMenu(sidebarOpenedStatusState);
      });
  }

  checkNavigationEnd() {
    this.router.events
      .pipe(
        pairwise(),
        tap(([previousEvent, currentEvent]) => {
          if (currentEvent instanceof NavigationCancel) {
            this.handleAfterRedirect(this.router.url);
          }
        }),
        map(([previousEvent, currentEvent]) => currentEvent),
        filter((event) => event instanceof NavigationEnd),
        startWith({ urlAfterRedirects: this.router.url }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((event) => {
        this.layoutService.showLoading = false;
        this.handleAfterRedirect((event as NavigationEnd).urlAfterRedirects);
      });
  }

  handleAfterRedirect(url: string) {
    this.store.dispatch(LayoutActions.setCurrentUrl({ url }));
    // overview url /member/detail/:id/:sectionKey
    const isOverview = url.includes('/member/detail/') && url.split('/').filter((element) => element).length === 4;
    const isEditFundCapability =
      url.includes('fund-list/capability-management/') && url.split('/').filter((element) => element).length === 3;
    const isInitialPaymentConfiguration = url.includes('/member/system-view/initial-payment-configuration');
    const isFinalPaymentConfiguration = url.includes('/member/system-view/final-payment-configuration');

    this.isDisabledMenu = isOverview
      || isEditFundCapability
      || isFinalPaymentConfiguration
      || isInitialPaymentConfiguration;
    const tabName = this.getTabByUrl(url);
    if (
      url !== '/fund-list' &&
      this.checkPermissionService.permission &&
      !this.checkPermissionService.checkCondition(url, this.checkPermissionService.permission)
    ) {
      url = this.checkPermissionService.getFirstAccessibleUrl(
        PERMISSION_KEY[TAB_PERMISSION[tabName as DisplayedTabName]],
      );
    }
    if ([DisplayedTabName.IndividualParticipant, DisplayedTabName.SearchedParticipant].includes(tabName as any)) {
      const memberId = this.getMemberIdFromUrl(url) ?? '';

      if (memberId && memberId !== 'entities' && memberId !== 'benefit-mapping') {
        this.store.dispatch(
          LayoutActions.profileNavigationItemRequest({
            memberId,
          }),
        );
        this.calculationStore.dispatch(getQDROLabelNameAction());
      }
    }

    if (tabName === DisplayedTabName.Employers) {
      this.getDynamicEmployerMenuGroup(url);
    }

    if (tabName === DisplayedTabName.IndividualEmployer) {
      this.getDynamicIndividualEmployerMenuGroup(url);
    }

    this.store.dispatch(LayoutActions.selectTab({ tab: tabName, url }));
    this.getCurrentMenuGroups();
    const sidebarOpenedStatus = this.getDefaultSidebarOpenedState(url);
    this.store.dispatch(LayoutActions.setSidebarOpenedStatus({ sidebarOpenedStatus }));
  }

  getDynamicEmployerMenuGroup(url: string) {
    let currentMenuGroup = deepClone(this.allMenuGroups)[DisplayedTabName.Employers];
    if (!currentMenuGroup) {
      return;
    }
    currentMenuGroup = this.getCurrentEmployerMenuGroup(url, currentMenuGroup);
    this.updateAllMenuGroup(DisplayedTabName.Employers, currentMenuGroup);
    this.getCurrentMenuGroups();
    this.getDynamicEmployerPensionReport();
  }

  getDynamicIndividualEmployerMenuGroup(url: string) {
    let currentMenuGroup = deepClone(this.allMenuGroups)[DisplayedTabName.IndividualEmployer];
    if (!currentMenuGroup) {
      return;
    }

    const isBVFFFund = this.layoutService.fundType === FundType.BVFF;
    if (!isBVFFFund) {
      currentMenuGroup = [currentMenuGroup[0]];
    }
    currentMenuGroup = this.getCurrentEmployerMenuGroup(url, currentMenuGroup);
    this.updateAllMenuGroup(DisplayedTabName.IndividualEmployer, currentMenuGroup);
    this.getCurrentMenuGroups();
  }

  getCurrentEmployerMenuGroup(url: string, menuGroups: MenuGroup[]): MenuGroup[] {
    let currentMenuGroups = deepClone(menuGroups);
    const isBVFFFund = this.layoutService.fundType === FundType.BVFF;

    // Handle menu for all Employers
    if (
      this.router.url === '/employer/employer-management' ||
      this.router.url === '/employer/report-transactions' ||
      this.router.url === '/employer/fee-amounts' ||
      this.router.url === '/employer/tolerance/parameters' ||
      this.router.url === '/employer/contribution-interest-rate' ||
      this.router.url === '/employer/report/annual-certification' ||
      this.router.url === '/employer/document-list' ||
      this.router.url === '/employer/generated-document' ||
      this.router.url === '/employer/individual-document' ||
      this.router.url === '/employer/report-disbursement-summary' ||
      this.router.url.includes('/employer/report/pension-report/') ||
      this.router.url.includes('/employer/report/relief-report/') ||
      this.router.url === '/employer/annual-fee-letter' ||
      this.router.url === '/employer/late-fee-reminder-letter'
    ) {
      currentMenuGroups[0].menu = [];
      if (isBVFFFund) {
        currentMenuGroups[1] = BVFF_EMPLOYERS_REPORT_MENU_GROUP;
        currentMenuGroups[2] = BVFF_EMPLOYERS_SETTING_MENU_GROUP;
        currentMenuGroups[3] = EMPLOYER_DOCUMENT_MENU_GROUP;
      }

      return currentMenuGroups;
    }

    // Check Document permission
    let listPermissionKeys = deepFlattenArray(deepClone(this.currentFund?.modules || []), 'subModules');
    let haveDocumentPermission = listPermissionKeys.some((item: any) =>
        (item.moduleKey === PERMISSION_KEY.EMPLOYER_DOCUMENT
        && item.clientModuleIsDisabled === false));


    // Set menu for muni overview incase toggle document module is off
    let EMPLOYER_OVERVIEW_MENU_WITHOUT_DOCUMENT = BVFF_EMPLOYER_OVERVIEW_MENU_LIST.filter(item => item.title !== "Documents");
    // Set menu for muni overview incase toggle document module is off
    let firstMenu = EMPLOYER_OVERVIEW_MENU_LIST.map((item: any) => item.menuItems)[0]?.filter((ele: any) => ele.name !== 'Document List');
    let secondMenu = EMPLOYER_OVERVIEW_MENU_LIST.map((item: any) => item.menuItems)[1];
    let EMPLOYER_OVERVIEW_MENU_LIST_WITHOUT_DOCUMENT : Menu[] = [
      {
        title: '',
        menuItems: firstMenu
      },
      {
        title: 'Employer Settings',
        menuItems: secondMenu
      }
    ]

    let employerOverviewMenuList = [ ...deepClone(isBVFFFund ?
          (haveDocumentPermission ? BVFF_EMPLOYER_OVERVIEW_MENU_LIST: EMPLOYER_OVERVIEW_MENU_WITHOUT_DOCUMENT)
        : (haveDocumentPermission ? EMPLOYER_OVERVIEW_MENU_LIST : EMPLOYER_OVERVIEW_MENU_LIST_WITHOUT_DOCUMENT)),
];
    let employerOverviewReportMenuList = [...deepClone(isBVFFFund ? BVFF_EMPLOYER_OVERVIEW_REPORT_PENSION_MENU : [])];
    if (url.split('/')[3]) {
      let employerId = url.split('/')[3]?.split('?')?.[0];
      employerOverviewMenuList.forEach((menu) => {
        menu.menuItems = menu.menuItems?.map((menuItems) => {
          if (isBVFFFund && menu.title === MUNICIPALITY_PARTICIPANTS_MENU_TITLE) {
            menuItems.queryParams = {
              ...menuItems.queryParams,
              municipalityId: employerId,
            };
          } else if (!menuItems.routerLink.includes(employerId)) {
            menuItems.routerLink = `${menuItems.routerLink}/${employerId}`;
          }
          return { ...menuItems };
        });
      });
      employerOverviewReportMenuList.forEach((menu) => {
        menu.menuItems = menu.menuItems?.map((menuItems) => {
          if (!menuItems.routerLink.includes(employerId)) {
            menuItems.routerLink = `${menuItems.routerLink}/${employerId}`;
          }
          return { ...menuItems };
        });
      });
    }

    if (isBVFFFund) {
      // BVFF Funds only have Main and Report menu group
      currentMenuGroups[0].menu = [...deepClone(employerOverviewMenuList)];
      currentMenuGroups[1].menu = [...deepClone(employerOverviewReportMenuList)];
      currentMenuGroups[1].icon = 'stacked_bar_chart';
    } else {
      // Non-BVFF Funds only have 1 Main menu group
      currentMenuGroups[0].menu = [...deepClone(employerOverviewMenuList)];
      currentMenuGroups = deepClone([currentMenuGroups[0]]);
    }
    return currentMenuGroups;
  }

  getDefaultSidebarOpenedState(url: string) {
    const showSideMenuUrls = [
      '/member/summary-view/',
      '/employer/detail/',
    ];

    const hiddenSideMenuUrls = [
      '/fund-management/app-content',
      '/member/member-metadata',
      '/processing/federal-taxes',
      '/processing/multiple-bank-account',
      '/processing/bank-holiday',
      '/processing/blackout-date',
      '/processing/config-cut-off-date',
      '/processing/manage-calendar',
      '/member/profile-overview-configuration',
      '/fund-management/preferences/participant-id',
      '/employer/employer-list-configuration',
      '/employer/employer-overview-configuration',
      '/employer/employer-information-configuration',
      '/fund-list/capability-management/',
      '/employer/remittance-submission/',
      '/member/calculation/retirement-benefit-calculation-list',
      '/member/calculation/retirement-benefit-calculation/',
      '/member/calculation/retirement-benefit-input-outputs',
      '/member/calculation/step-configuration/',
      '/member/calculation/calculation-parameter-configuration/',
      '/member/calculation/benefit-calculation-configuration/',
      '/member/calculation/exception-configuration/',
      '/member/calculation/subheader-configuration/',
      'member/distribution-code-mapping/',
    ];
    const hiddenSideMenuEqualUrls = ['/permission', '/fund-list', '/processing'];
    if (
      hiddenSideMenuUrls.some((hiddenUrl) => url.includes(hiddenUrl)) ||
      hiddenSideMenuEqualUrls.some((hiddenUrl) => url === hiddenUrl)
    ) {
      return SidebarOpenedStatus.Hidden;
    }
    const closedSideMenuUrls = ['/member', '/member/benefit', '/employer/employer-management'];
    if (closedSideMenuUrls.some((closedUrl) => url === closedUrl || url.split('?')[0] === closedUrl)) {
      return SidebarOpenedStatus.Closed;
    }

    if (
      this.sidebarOpenedStatus === SidebarOpenedStatus.Hidden
      || showSideMenuUrls.some((showUrl) => url.includes(showUrl))
    ) {
      return SidebarOpenedStatus.Opened;
    }
    return this.sidebarOpenedStatus;
  }

  getTabByUrl(url: string) {
    if (!url) {
      return HiddenTabName.Hidden;
    }
    if (OVERVIEW_URLS.some((item) => url.includes(item))) {
      if (url.includes(STRING_QUERY_PARAM.SEARCH)) {
        return DisplayedTabName.SearchedParticipant;
      }
      return DisplayedTabName.IndividualParticipant;
    }
    if (INDIVIDUAL_EMPLOYER_URLS.some((item) => url.includes(item))) {
      return DisplayedTabName.IndividualEmployer;
    }
    if (TAB_URLS[DisplayedTabName.Employers].some((item) => url.includes(item))) {
      return DisplayedTabName.Employers;
    }
    if (TAB_URLS[DisplayedTabName.Participants].some((item) => url.includes(item))) {
      return DisplayedTabName.Participants;
    }
    if (TAB_URLS[DisplayedTabName.AppContent].some((item) => url.includes(item))) {
      return DisplayedTabName.AppContent;
    }
    if (TAB_URLS[DisplayedTabName.Admin].some((item) => url.includes(item))) {
      return DisplayedTabName.Admin;
    }
    if (TAB_URLS[DisplayedTabName.Processing].some((item) => url.includes(item))) {
      return DisplayedTabName.Processing;
    }
    return HiddenTabName.Hidden;
  }

  getAllMenuGroups() {
    combineLatest([
      this.store
        .select(fromLayoutReducer.selectAllMenuGroupsState)
        .pipe(distinctUntilChanged((previous, current) => deepEqual(previous, current))),
      this.store.select(fromLayoutReducer.selectMemberNavigationListState),
    ])
      .pipe(
        distinctUntilChanged((previous, current) => deepEqual(previous, current)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(([allMenuGroupsState, memberNavigationListState]) => {
        this.isMember = !!memberNavigationListState?.isMember;
        this.allMenuGroups = allMenuGroupsState;
        if (memberNavigationListState?.id || this.forceReloadMenu) {
          this.forceReloadMenu = false;
          this.getCurrentMenuGroups();
        }
      });
  }

  checkSelectedTab() {
    this.store
      .select(fromLayoutReducer.selectTabsState)
      .pipe(
        distinctUntilChanged((previous, current) => deepEqual(previous, current)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((tabs) => {
        this.tabs = tabs;
        const currentTab = this.getCurrentTab(tabs);
        if (currentTab && currentTab.url) {
          if (currentTab.tabName === DisplayedTabName.Participants && this.authService.isAdminPortal$.value) {
            this.store.dispatch(LayoutActions.getBulkContentItemRequest());
          }
          if (this.router.url !== currentTab.url) {
            if (SHOULD_NOT_REDIRECT_TO_EMPLOYER_TAB_URL_LIST.some(url => this.router.url.includes(url))
              && currentTab.tabName === DisplayedTabName.Employers) {
              return;
            }
            void this.router.navigateByUrl(currentTab.url);
            return;
          }
        }
      });
  }

  getCurrentTab(tabs: Tab[]) {
    return (deepFlattenArray(tabs) as Tab[]).find((item) => item.isSelected);
  }

  getMemberIdFromUrl(url: string) {
    if (!url) {
      return null;
    }
    const listElement = url.split('?')[0].split('/');
    if (
      url.includes('member/profile-header-configuration') ||
      url.includes('member/profile-overview-configuration') ||
      url.includes('member/profile-navigation-configuration') ||
      url.includes('/member/retirement-benefit') ||
      url.includes('/member/refunds-overview')
    ) {
      return listElement[3];
    }
    if (url.includes('/member/benefit-overview') || url.includes('/member/disability-overview')) {
      return listElement[4];
    }
    return listElement[listElement.length - 1];
  }

  getPaymentInfoParams() {
    this.store.pipe(select(fromLayoutReducer.selectPaymentInfoParamsState)).subscribe((selectPaymentInfoParams) => {
      if (selectPaymentInfoParams) {
        this.paymentInfoParams = selectPaymentInfoParams;
      }
    });
  }

  getCurrentMenuGroups() {
    const tabName = this.getTabByUrl(this.router.url);
    if (!tabName) {
      return;
    }
    let currentMenuGroups = (deepClone(this.allMenuGroups) as any)[tabName] as MenuGroup[];

    if (
      [DisplayedTabName.IndividualParticipant, DisplayedTabName.SearchedParticipant].includes(
        tabName as DisplayedTabName,
      )
    ) {
      const memberId = this.getMemberIdFromUrl(this.router.url) || '';

      this.getPaymentInfoParams();

      currentMenuGroups = currentMenuGroups.map((currentMenuGroup) => {
        currentMenuGroup.menu = (currentMenuGroup.menu || []).map((menu) => {
          (menu.menuItems || []).map((menuItem) => {
            // menuItem.routerLink = `${menuItem.routerLink}/${memberId}`;
            menuItem.routerLink =
              '/' +
              menuItem.routerLink
                .split('/')
                .concat([memberId])
                .filter((x) => x)
                .join('/');
            if (tabName === DisplayedTabName.SearchedParticipant) {
              menuItem.queryParams = { search: true };
              menuItem.routerLinkExpand = [`${menuItem.routerLink}?${STRING_QUERY_PARAM.SEARCH}`];
            }

            if (
              menuItem?.routerLink.includes('system-view/payment-information') &&
              !menuItem?.routerLink.includes('paymentSourceId') &&
              this.paymentInfoParams
            ) {
              menuItem.queryParams = this.paymentInfoParams;
            }

            if (
              this.currentEntityProfile &&
              (menuItem?.routerLink.includes('system-view/') ||
                menuItem?.routerLink.includes('member/summary-view/') ||
                menuItem?.routerLink.includes('member/detail-view/'))
            ) {
              menuItem.queryParams = { ...menuItem.queryParams, entityReferenceLinkedId: this.currentEntityProfile };
              menuItem.routerLinkExpand = [
                `${menuItem.routerLink}${
                  menuItem.routerLinkExpand?.[0]?.includes('?') ? '&' : '?'
                }entityReferenceLinkedId=${this.currentEntityProfile}`,
              ];
            }

            if (
              menuItem?.routerLink.includes('/member/system-view/payment-information')
            ) {
              menuItem.routerLinkExpand?.push(`/member/system-view/initial-payment-configuration`);
              menuItem.routerLinkExpand?.push(`/member/system-view/final-payment-configuration`);
            }

            return menuItem;
          });
          return menu;
        });
        return currentMenuGroup;
      });
    }

    const allPermissions = deepFlattenArray(
      deepClone(this.checkPermissionService.getPermissionFilterByModule() || []),
      'Child',
    );
    const allSubModules = deepFlattenArray(deepClone(this.currentFund?.modules || []), 'subModules').filter(
      (item) =>
        item.parentId &&
        !item.clientModuleIsDisabled &&
        allPermissions.find((permission) => permission.Key === item.moduleKey),
    );

    // Used for checking logic of display Employer Portal Menu
    let listPermissionKeys = deepFlattenArray(deepClone(this.currentFund?.modules || []), 'subModules');
    let isHaveEmployerPermission =
      listPermissionKeys.some(item =>
        item.moduleKey === PERMISSION_KEY.EMPLOYERS
        && item.clientModuleIsDisabled === false);
    let accessManagementIndex = currentMenuGroups.findIndex(item => item.moduleKey === PERMISSION_KEY.ACCESS_CONTROL);

    if (accessManagementIndex !== -1) {
      let employerPortalIndex =
        currentMenuGroups?.[accessManagementIndex]?.menu?.findIndex(item => item.title === 'Employer Portal');
      if (employerPortalIndex !== -1 && !isHaveEmployerPermission) {
        const menuGroup = deepClone(currentMenuGroups) as any;
        menuGroup[accessManagementIndex]['menu'][employerPortalIndex as number] = [];
        currentMenuGroups = menuGroup;
      }
    }
    // End of used for checking logic of display Employer Portal Menu

    const isIgnoreTabs = [
      DisplayedTabName.IndividualParticipant,
      DisplayedTabName.SearchedParticipant,
    ].includes(
      tabName as DisplayedTabName,
    );

    if (currentMenuGroups) {
      currentMenuGroups = currentMenuGroups
        .filter((menuGroup) => this.checkVersionFlag(menuGroup?.versionFlag))
        .map((menuGroup) => {
          if (this.currentFund) {
            menuGroup.isHidden =
              this.authService.isAdminPortal$.value &&
              !isIgnoreTabs &&
              !allSubModules.find((subModule) => subModule.moduleKey === menuGroup.moduleKey);
            if (!this.isMember && menuGroup.name === 'retirement-benefit') {
              menuGroup.isHidden = true;
            }
            menuGroup.menu = menuGroup.menu
              ?.filter(
                (menu) =>
                  this.checkVersionFlag(menu?.versionFlag) &&
                  (isEmpty(menu?.fundType) || menu?.fundType === this.currentFund?.fundType),
              )
              ?.map((menu) => {
                menu.menuItems =
                  deepClone(menu)
                    ?.menuItems?.filter(
                      (menuItem) =>
                        isEmpty(menuItem?.fundType) ||
                        menuItem?.fundType === this.currentFund?.fundType ||
                        (Array.isArray(menuItem.fundType) &&
                          menuItem.fundType.find((f) => f === this.currentFund.fundType)),
                    )
                    ?.map((menuItem) => {
                      if (menuItem.id === CalculationType[CalculationType.QDRO] && this.qdroLabelName) {
                        menuItem.name = this.qdroLabelName;
                      }
                      return menuItem;
                    }) || [];
                return menu;
              });
          }
          menuGroup.isSelected =
            this.checkSelectedMenuItem(menuGroup) ||
            menuGroup.menu?.some(
              (menu) =>
                menu.menuItems?.some(
                  (menuItem) => this.checkSelectedMenuItem(menuItem) || this.router.url.includes(menuItem.routerLink),
                ),
            );
          return menuGroup;
        });
      this.menuGroups = currentMenuGroups;
      this.setCurrentMenuGroups();
    }
  }

  checkVersionFlag(versionFlag?: string | string[]) {
    if (!versionFlag) {
      return true;
    }
    return isEmpty(versionFlag) || this.checkVersionFlagService.checkVersionFlag(versionFlag);
  }

  checkSelectedMenuItem(menuItem: any) {
    return (
      this.router.url === menuItem?.routerLink ||
      this.router.url.split('?')[0] === menuItem?.routerLink ||
      (menuItem?.routerLinkExpand && this.checkSelectedMenuLinkExpand(menuItem?.routerLinkExpand))
    );
  }

  checkSelectedMenuLinkExpand(routerLinkExpand: string[]) {
    let check = false;
    routerLinkExpand.forEach((routerLink) => {
      if (this.router.url.includes(routerLink)) {
        check = true;
      }
    });
    return check;
  }

  loadAllDynamicMenu() {
    if (!this.authService.isAdminPortal$.value) {
      return;
    }
    this.checkPermissionToLoadDynamicMenu(this.authService.Permissions);
  }

  checkPermissionToLoadDynamicMenu(permissions: PermissionModel[]) {
    if (!permissions) {
      return;
    }
    const parentPermissions = permissions.map((permission) => permission.Key);
    if (this.authService.isSysAdmin || parentPermissions?.includes(PERMISSION_KEY.PARTICIPANTS)) {
      this.getMemberDynamicMenu();
      this.getMenuProfileNavigation();
      this.getParticipantReportMenu();
    }
    if (this.authService.isSysAdmin || parentPermissions?.includes(PERMISSION_KEY.APP_CONTENT)) {
      this.getAppContentDynamicMenu();
    }
  }

  getDynamicMenu(
    menu: MenuGroup,
    items: any[],
    options: {
      nameKey: string;
      routerLinkPrefix: string;
      routerLinkKey: string;
      menuIndex?: number;
    },
  ): MenuGroup {
    const menuItems = deepClone(items).map((menuItem: any) => {
      return {
        name: menuItem[options.nameKey],
        routerLink: `${options.routerLinkPrefix}/${menuItem[options.routerLinkKey]}`,
      };
    });
    const currentMenu = deepClone(menu) as MenuGroup;
    const menuIndex = options.menuIndex || 0;
    if (currentMenu?.menu?.length) {
      currentMenu.menu[menuIndex].menuItems = [...menuItems, ...(currentMenu.menu[menuIndex].menuItems || [])];
    }
    return currentMenu;
  }

  getParticipantReportMenu() {
    this.store.dispatch(LayoutActions.getReportCategorySidebar());

    this.store
      .pipe(select(fromMember.selectParticipantReportCategorySidebar), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const allMenuGroups = deepClone(this.allMenuGroups) as Record<DisplayedTabName, MenuGroup[]>;
        const menuParticipantReportIndex = allMenuGroups[DisplayedTabName.Participants].findIndex(
          (menuGroup) => menuGroup?.name === 'participant_report',
        );
        const defaultParticipantReportMenuGroup = this.defaultTabMenuGroups?.[DisplayedTabName.Participants]?.find(
          (menuGroup) => menuGroup?.name === 'participant_report',
        );

        if (defaultParticipantReportMenuGroup && menuParticipantReportIndex > -1) {
          let menu: Menu[] = [];
          state.response.reportCategory.forEach((reportCategory: ReportCategorySidebar) => {
            menu.push({
              title: reportCategory.name,
              menuItems: deepClone(reportCategory.reports).map((report: ReportSidebar) => {
                return {
                  name: report.name,
                  routerLink: `/member/report/${report.id}`,
                };
              }),
            });
          });
          const currentMenu = deepClone(defaultParticipantReportMenuGroup) as MenuGroup;
          menu = menu.concat(currentMenu.menu ?? []);

          allMenuGroups[DisplayedTabName.Participants][menuParticipantReportIndex].menu = menu;
        }
        this.updateAllMenuGroup(DisplayedTabName.Participants, allMenuGroups[DisplayedTabName.Participants]);
      });
  }

  getMemberDynamicMenu() {
    this.store
      .select(fromLayoutReducer.selectBulkContentState)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((bulkContentState) => {
        if (bulkContentState.isLoading) {
          return;
        }
        const allMenuGroups = deepClone(this.allMenuGroups) as Record<DisplayedTabName, MenuGroup[]>;
        const menuBulkUpdateIndex = allMenuGroups[DisplayedTabName.Participants].findIndex(
          (menuGroup) => menuGroup?.name === 'bulk_update',
        );
        const defaultBulkUpdateMenuGroup = this.defaultTabMenuGroups?.[DisplayedTabName.Participants]?.find(
          (menuGroup) => menuGroup?.name === 'bulk_update',
        );
        if (defaultBulkUpdateMenuGroup && menuBulkUpdateIndex > -1) {
          const dynamicMenu = this.getDynamicMenu(defaultBulkUpdateMenuGroup, bulkContentState.items, {
            nameKey: 'importKey',
            routerLinkPrefix: '/member/bulk',
            routerLinkKey: 'key',
          });
          allMenuGroups[DisplayedTabName.Participants][menuBulkUpdateIndex] = dynamicMenu;
        }
        this.updateAllMenuGroup(DisplayedTabName.Participants, allMenuGroups[DisplayedTabName.Participants]);
      });

    this.store
      .pipe(select(fromReducer.selectMetadataUpdated), takeUntil(this.unsubscribe$))
      .subscribe((metadataUpdated) => {
        if (!this.authService.isAdminPortal$.value) {
          return;
        }
        this.store.dispatch(LayoutActions.getBulkContentItemRequest());
      });
  }

  getAppContentDynamicMenu() {
    this.store
      .select(fromLayoutReducer.selectAppContentState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((appContentState) => {
        if (appContentState.isLoading) {
          return;
        }
        const allMenuGroups = deepClone(this.allMenuGroups) as Record<DisplayedTabName, MenuGroup[]>;
        const appContentMenuIndex = allMenuGroups[DisplayedTabName.AppContent]?.findIndex(
          (menuGroup) => menuGroup.name === 'ACM',
        );
        const defaultAppContentMenuGroup = deepClone(DEFAULT_TAB_MENU_GROUPS[DisplayedTabName.AppContent]).find(
          (menuGroup) => menuGroup?.name === 'ACM',
        );

        if (defaultAppContentMenuGroup && appContentMenuIndex > -1) {
          allMenuGroups[DisplayedTabName.AppContent][appContentMenuIndex] = this.getDynamicMenu(
            defaultAppContentMenuGroup,
            appContentState.items,
            {
              nameKey: 'navigation',
              routerLinkPrefix: '/page',
              routerLinkKey: 'id',
              menuIndex: 1,
            },
          );
        }
        this.updateAllMenuGroup(DisplayedTabName.AppContent, allMenuGroups[DisplayedTabName.AppContent]);
      });

    // Update to use store
    this.store.pipe(select(fromReducer.selectNavigationUpdated), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.store.dispatch(LayoutActions.getAppContentItemRequest());
    });
  }

  getMenuProfileNavigation() {
    this.store
      .pipe(select(fromReducer.selectProfileNavigationUpdated), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el) {
          const memberId = this.getMemberIdFromUrl(this.router.url) || '';
          if (memberId) {
            this.store.dispatch(LayoutActions.profileNavigationItemRequest({ memberId }));
          }
        }
      });

    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.memberId) {
          this.disabledSideBar = !state.isReloading;
        }
        if (!state.isReloading || !state.memberId) {
          return;
        }
        if (state?.memberNavigationList?.entityId) {
          this.currentEntityProfile = state?.memberNavigationList?.entityId;
        }

        const currentTabName = this.getCurrentTab(this.tabs)?.tabName as DisplayedTabName;
        if ([DisplayedTabName.IndividualParticipant, DisplayedTabName.SearchedParticipant].includes(currentTabName)) {
          const currentMenuGroup = deepClone(this.allMenuGroups)[currentTabName as DisplayedTabName];
          if (!currentMenuGroup) {
            return;
          }
          // TODO: check after move logic
          if (!(state?.menu ?? []).find((item) => !item.title)) {
            currentMenuGroup[0].menu = [...deepClone(MEMBER_OVERVIEW_MENU_LIST), ...deepClone(state?.menu ?? [])];
          } else {
            currentMenuGroup[0].menu = [...deepClone(state?.menu ?? [])];
          }
          this.updateAllMenuGroup(currentTabName, currentMenuGroup);
        }

        if ([DisplayedTabName.IndividualEmployer].includes(currentTabName)) {
          const currentMenuGroup = deepClone(this.allMenuGroups)[currentTabName as DisplayedTabName];
          if (!currentMenuGroup) {
            return;
          }
          this.updateAllMenuGroup(currentTabName, currentMenuGroup);
        }
      });
  }

  checkFundChange() {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        distinctUntilChanged((previous, current) => deepEqual(previous, current)),
        tap((currentFund) => {
          this.currentFund = currentFund;
          this.updateTabsLabel();
        }),
        pairwise(),
        tap(([previous, current]) => {
          if (previous.id !== current.id) {
            this.store.dispatch(
              LayoutActions.closeTab({
                tabName: DisplayedTabName.IndividualParticipant,
              }),
            );
            this.store.dispatch(
              LayoutActions.closeTab({
                tabName: DisplayedTabName.SearchedParticipant,
              }),
            );
            this.store.dispatch(
              LayoutActions.closeTab({
                tabName: DisplayedTabName.IndividualEmployer,
              }),
            );
            this.store.dispatch(
              LayoutActions.setTabUrl({
                tab: DisplayedTabName.Employers,
                url: '/employer/employer-management',
              }),
            );
          }
        }),
        filter(([previous, current]) => !!previous.id && !!current.id),
        map(([previous, current]) => current),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((currentFund) => {
        if (
          !this.checkPermissionService.checkCondition(
            this.router.url,
            this.checkPermissionService.getPermissionFilterByModule(),
          )
        ) {
          return;
        }
        this.store.dispatch(
          LayoutActions.setAllMenuGroups({
            allMenuGroups: this.currentFund.fundType === FundType.BVFF ? deepClone(this.allMenuGroups ?? this.defaultTabMenuGroups) : deepClone(this.defaultTabMenuGroups),
          }),
        );
        this.memberStore.dispatch(MemberListActions.setMemberFilter({ filters: [] }));
        if (currentFund?.id && this.authService.isAdminPortal$.value) {
          this.store.dispatch(LayoutActions.getBulkContentItemRequest());
          this.store.dispatch(LayoutActions.getAppContentItemRequest());
          this.store.dispatch(
            LayoutActions.setTabUrl({
              tab: DisplayedTabName.Employers,
              url: '/employer/employer-management',
            }),
          );
          this.getDynamicEmployerPensionReport();
          this.store.dispatch(LayoutActions.getReportCategorySidebar());
        }
      });
  }

  updateAllMenuGroup(tabName: DisplayedTabName, menuGroups: MenuGroup[]) {
    this.forceReloadMenu = true;
    const allMenuGroups = {
      ...deepClone(this.allMenuGroups),
      [tabName]: menuGroups,
    };
    this.store.dispatch(LayoutActions.setAllMenuGroups({ allMenuGroups }));
  }

  changeSidebarOpenedStatus(sidebarOpenedStatus: SidebarOpenedStatus) {
    this.store.dispatch(LayoutActions.setSidebarOpenedStatus({ sidebarOpenedStatus }));
  }

  closeSideMenu() {
    this.changeSidebarOpenedStatus(SidebarOpenedStatus.Closed);
  }

  controlSideMenu(sidebarOpenedStatus: SidebarOpenedStatus) {
    if (sidebarOpenedStatus === SidebarOpenedStatus.Opened) {
      this.renderer.removeClass(document.body, 'menu-collapsed');
      this.renderer.removeClass(document.body, 'menu-hidden');
    } else if (sidebarOpenedStatus === SidebarOpenedStatus.Closed) {
      this.renderer.addClass(document.body, 'menu-collapsed');
      this.renderer.removeClass(document.body, 'menu-hidden');
    } else {
      this.renderer.addClass(document.body, 'menu-hidden');
      this.renderer.removeClass(document.body, 'menu-collapsed');
    }
  }

  updateTabsLabel() {
    const modules: Module[] = this.currentFund.modules;
    if (!modules) {
      return;
    }
    const allModule = (deepFlattenArray(deepClone(modules)) || []).filter((module) => !module.clientModuleIsDisabled);
    const tabs = deepClone(this.tabs).map((tab) => {
      const ignoreTabConfigTabs = [DisplayedTabName.IndividualParticipant, DisplayedTabName.SearchedParticipant, DisplayedTabName.IndividualEmployer];
      if (ignoreTabConfigTabs.includes(tab.tabName as DisplayedTabName)) {
        return tab;
      }
      const moduleConfig = allModule.find((item) => item.moduleKey === tab.tabName);
      if (this.authService.isAdminPortal$.value || tab.tabName !== DisplayedTabName.Admin) {
        tab.tabLabel = moduleConfig?.clientModuleName || tab.tabLabel;
      }
      tab.isHidden = !moduleConfig;
      return tab;
    });
    this.store.dispatch(LayoutActions.setTabs({ tabs }));
  }

  setDefaultLayoutValue() {
    this.store.dispatch(
      LayoutActions.setTabs({
        tabs: this.defaultTabs,
      }),
    );
    this.store.dispatch(
      LayoutActions.setAllMenuGroups({
        allMenuGroups: this.defaultTabMenuGroups,
      }),
    );
  }

  reloadCurrentFund() {
    if (!this.layoutService.currentFund$.value.key) {
      return;
    }
    this.resourceCenterService
      .getFundByKey(this.layoutService.currentFund$.value.key)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (fundModel: FundModel) => {
          this.store.dispatch(FundListActions.selectFund(getCurrentFundData(fundModel)));
        },
        (error: any) => {},
      );
  }

  getDynamicEmployerPensionReport() {
    this.employerService
      .getReportCategorySideBar({ reportType: ReportCategoryType.PensionReport })
      .subscribe((response) => {
        this.updateReportMenu(response.reportCategory, 'pension-reports', '/employer/report/pension-report');
      });
    this.employerService
      .getReportCategorySideBar({ reportType: ReportCategoryType.ReliefReport })
      .subscribe((response) => {
        this.updateReportMenu(response.reportCategory, 'relief-reports', '/employer/report/relief-report');
      });
  }

  private updateReportMenu(reportCategory: ReportCategorySidebar[], menuId: string, baseUrl: string) {
    const allMenuGroups = deepClone(this.allMenuGroups) as Record<DisplayedTabName, MenuGroup[]>;
    const menuEmployerReportIndex = allMenuGroups[DisplayedTabName.Employers].findIndex(
      (menuGroup) => menuGroup?.name === 'employer_report',
    );
    if (menuEmployerReportIndex > -1) {
      let menu: Menu[] = [];
      const pensionReport: Menu[] = deepClone(reportCategory).map((reportCategory: ReportCategorySidebar) => {
        return {
          title: reportCategory.name,
          menuItems: deepClone(reportCategory.reports).map((report: ReportSidebar) => {
            return {
              name: report.name,
              routerLink: `${baseUrl}/${report.id}`,
            };
          }),
        } as Menu;
      });
      const currentMenu = deepClone(
        allMenuGroups[DisplayedTabName.Employers][menuEmployerReportIndex] ?? { menu: [] },
      ) as MenuGroup;

      const currentPensionReportIndex = currentMenu.menu?.findIndex((item) => item.id === menuId) ?? -1;
      const menuEmployer = this.allMenuGroups?.employers?.find((menuGroup) => menuGroup?.name === 'employer_report');
      const menuEmployerReliefReport = menuEmployer?.menu?.find((item) => item.id === 'relief-reports');

      if (currentPensionReportIndex > -1 && pensionReport?.length) {
        currentMenu.menu = [...(currentMenu.menu ?? [])] as Menu[];
        if (pensionReport[0].title !== 'Relief Reports') {
          currentMenu.menu[currentPensionReportIndex] = pensionReport[0];
        } else {
          if (!!menuEmployerReliefReport) {
            currentMenu.menu[currentPensionReportIndex] = {
              title: pensionReport[0].title,
              menuItems:
                pensionReport[0].menuItems && menuEmployerReliefReport.menuItems
                  ? [...pensionReport[0].menuItems, ...menuEmployerReliefReport.menuItems]
                  : undefined,
            };
          }
        }
      }
      menu = menu.concat(currentMenu.menu ?? []);
      allMenuGroups[DisplayedTabName.Employers][menuEmployerReportIndex].menu = menu;
    }
    this.updateAllMenuGroup(DisplayedTabName.Employers, allMenuGroups[DisplayedTabName.Employers]);
  }

  setCurrentMenuGroups() {
    this.store.dispatch(LayoutActions.setCurrentMenuGroups({ menuGroups: this.menuGroups }));
  }

  private getQDROLabelName(): void {
    this.store
      .select(getCalculationQDROSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((calculationQDROState) => {
        this.qdroLabelName = calculationQDROState?.payload?.labelName ?? CalculationType[CalculationType.QDRO];
      });
  }

  private checkOpenedDialog() {
    this.dialog.afterOpened
      .pipe(
        map((res) => !!res),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((dialogRefExist: boolean) => {
        this.loadingCustomClass = 'fixed w-screen h-screen';
      });

    this.dialog.afterAllClosed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.loadingCustomClass = 'absolute h-full w-full';
    });
  }

  onScrollMainContent() {
    if (!this.globalLoadingIndicator?.nativeElement) {
      return;
    }
    const scrollTop = (this.mainContent?.nativeElement as HTMLElement)?.scrollTop ?? 0;
    this.renderer.setStyle(this.globalLoadingIndicator.nativeElement, 'top', `${scrollTop}px`);
  }
}
