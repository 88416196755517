<div class="payment-instruction-detail">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" [settings]="settings"></ptg-breadcrumb>
  <ptg-payment-header
    [style.display]="!selectedHeaderBenefit ? 'none' : ''"
    [customData]="customData"
    (selectedHeaderBenefitEmitter)="getSelectedHeaderBenefit($event)"
    (headerBenefitEmitter)="getHeaderBenefitList($event)"
    (headerConfigEmitter)="getHeaderConfigList($event)"
    (loadingEmitter)="setLoadingState($event)"
  ></ptg-payment-header>
  <ptg-overview-header-entity *ngIf="!selectedHeaderBenefit"></ptg-overview-header-entity>

  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <div *ngIf="selectedHeaderBenefit; else noDataTemplate" class="content flex flex-col p-6 gap-4">
      <!-- NOTIFICATION BANNER -->
      <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

      <!-- ERROR TAX DEDUCTION AMOUNTS CALCULATION BANNER -->
      <ptg-banner
        *ngIf="errorTaxCalculationBannerType"
        [(bannerType)]="errorTaxCalculationBannerType"
        [message]="errorTaxCalculationMessage"
      ></ptg-banner>

      <!-- TITLES -->
      <div class="title-payment" *ngIf="selectedRow">
        <ptg-note-memo-detail
          [paymentInfo]="selectedRow"
          [payeeRecordId]="memberId"
          [entityReferenceLinkedId]="entityReferenceLinkedId"
          [selectedHeaderBenefit]="selectedHeaderBenefit"
          [selectedTabPayment]="selectedTabPayment"
          [payrollRunId]="payrollRunId"
          [isLumpsumBenefit]="isLumpsumBenefit"
        ></ptg-note-memo-detail>
      </div>
      <!-- TERMINATION NOTIFICATION -->
      <ptg-banner
        *ngIf="notificationBannerType"
        [(bannerType)]="notificationBannerType"
        [message]="notificationBannerMessage"
        [isHideCloseButton]="true"
      >
        <ptg-button
          classInput="reverse-button secondary"
          *ngIf="
            ((selectedRow?.payStatus !== PayStatus.Finalized &&
              selectedRow?.prevPayStatus !== PayStatus.Terminated &&
              selectedRow?.prevPayStatus !== PayStatus.Suspended) ||
              selectedRow?.isDisplayTerminationBanner) &&
            isShowBtnReverse
          "
          (clickButton)="onReversePaymentStatus()"
        >
          <span class="flex items-center gap-1">
            <mat-icon>cached</mat-icon>
            <span>Reverse</span>
          </span>
        </ptg-button>
      </ptg-banner>

      <div class="flex gap-5" *ngIf="selectedRow">
        <!-- PAYMENT STATUS -->
        <div class="max-w-[calc(100%-0.625rem)] flex-1">
          <div class="section-container">
            <ptg-payment-status
              [selectedTabPayment]="selectedTabPayment"
              [selectedHeaderBenefit]="selectedHeaderBenefit"
              [selectedRow]="selectedRow"
              [memberId]="memberId"
              [dateOfDeath]="dateOfDeath"
              (changeBannerEmitter)="getBannerEventWithCustomMessage($event)"
              (reloadDataEmitter)="getReloadSignalFromChildren($event)"
            ></ptg-payment-status>
          </div>
        </div>
        <!-- PAYMENT ANNUAL TOTALS -->
        <div class="max-w-[calc(100%-0.625rem)] flex-1">
          <div class="section-container">
            <ptg-payment-annual-totals [selectedRow]="selectedRow" [memberId]="memberId"> </ptg-payment-annual-totals>
          </div>
        </div>
      </div>
      <div class="flex gap-3" *ngIf="selectedRow?.isCancelable">
        <button
          mat-stroked-button
          class="cancel-payment-button"
          [disabled]="cancelingPaymentInstruction"
          (click)="cancelPayment()"
        >
          Cancel Payment
        </button>
      </div>

      <!-- EARNINGS & DEDUCTIONS PAYROLL SETTINGS SECTIONS -->
      <div class="flex gap-3" *ngIf="selectedRow">
        <!-- EARNINGS -->
        <div class="section-container">
          <ptg-payment-earning-section
            [selectedHeaderBenefit]="selectedHeaderBenefit"
            [selectedRow]="selectedRow"
            [selectedTabPayment]="selectedTabPayment"
            [benefitTypeOptionId]="selectedHeaderBenefit.benefitTypeOptionId"
            (changeBannerEmitter)="getBannerEventWithCustomMessage($event)"
            (reloadDataEmitter)="getReloadSignalFromChildren($event)"
            [memberId]="memberId"
          ></ptg-payment-earning-section>
        </div>

        <!-- DEDUCTIONS -->
        <div class="section-container" *ngIf="selectedRow?.paymentType !== PaymentInstructionType.Reissue">
          <ptg-payment-deductions-section
            [selectedRow]="selectedRow"
            [selectedTabPayment]="selectedTabPayment"
            [memberId]="memberId"
            [benefitId]="selectedHeaderBenefit?.benefitId"
            [(reloadTime)]="reloadTime"
            (changeBannerEmitter)="getErrorTaxCalculationBannerMessage($event)"
            (reloadDataEmitter)="getReloadSignalFromChildren($event)"
            [isTabHistory]="selectedTabPayment === TabPaymentInfo.InstructionHistory"
          ></ptg-payment-deductions-section>
        </div>

        <!-- PAYROLL SETTINGS -->
        <div class="section-container" *ngIf="isShowPayrollSetting">
          <ptg-payroll-settings-detail
            [selectedRow]="selectedRow"
            [selectedTabPayment]="selectedTabPayment"
            [selectedHeaderBenefit]="selectedHeaderBenefit"
            (changeBannerEmitter)="getBannerEvent($event)"
            (reloadDataEmitter)="getReloadSignalFromChildren($event)"
            [memberId]="memberId"
          ></ptg-payroll-settings-detail>
        </div>
      </div>

      <!-- PAYMENT DETAILS -->
      <div
        class="payment-detail"
        *ngIf="selectedRow && selectedRow.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment"
        [class.hidden]="!isExpandedAdjustmentDetailsSection"
      >
        <ptg-split-payment-section
          [selectedRow]="selectedRow"
          [fitToParent]="true"
          [hideHeader]="true"
          [benefitTypeOptionId]="selectedHeaderBenefit.benefitTypeOptionId"
          [memberId]="memberId"
          [selectedTabPayment]="selectedTabPayment"
        >
          <ng-template cellContent columnName="name" let-row>
            <span class="font-medium">{{ row?.name }}</span>
          </ng-template>

          <ng-template cellContent columnName="accountNumber" let-row>
            <span *ngIf="row?.paymentMethod === 'Direct Deposit'; else blankData">
              {{ row?.bankName + ' - ' }}<span class="account-no">{{ row?.maskedAccountNumber }}</span
              >{{ row?.accountNumberNotMasked + ' - ' }}<span>{{ row?.accountTypeName }}</span>
            </span>
            <ng-template #blankData>-</ng-template>
          </ng-template>
        </ptg-split-payment-section>
      </div>

      <!-- ADJUSTMENT DETAILS -->
      <ptg-view-adjustment-details
        *ngIf="selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment"
        [class.hidden]="
          !selectedRow?.paymentInstructionHistoryId ||
          !isShowAdjustmentDetailsSection ||
          !isExpandedAdjustmentDetailsSection
        "
        [selectedRow]="selectedRow"
        (onDataListChange)="onGetAdjustmentDetails($event)"
      ></ptg-view-adjustment-details>

      <mat-icon
        *ngIf="selectedRow && selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.Adjustment"
        class="m-auto cursor-pointer"
        (click)="onClickExpandOrCollapseAdjustmentDetailsSection()"
      >
        {{ !isExpandedAdjustmentDetailsSection ? 'keyboard_double_arrow_down' : 'keyboard_double_arrow_up' }}
      </mat-icon>

      <!-- PAYMENT INFO TAB -->
      <ptg-payment-info-tab
        [selectedHeaderBenefit]="selectedHeaderBenefit"
        [memberId]="memberId"
        [(dateOfDeath)]="dateOfDeath"
        [(isLumpsumBenefit)]="isLumpsumBenefit"
        [selectedTabPayment]="selectedTabPayment"
        [selectedRowId]="selectedInstructionId"
        [getReloadSignalFromParent$]="reloadPaymentInfoTabTrigger$"
        (changeBannerEmitter)="getBannerEventWithCustomMessage($event)"
        (onChangeTab)="onChangeTab($event)"
        (onSelectRow)="onSelectRow($event)"
      ></ptg-payment-info-tab>
    </div>
    <ng-template #noDataTemplate>
      <div class="flex justify-center items-center h-full">
        <div class="no-columns-display">No Benefit to Display</div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="flex justify-center items-center h-full">
      <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
    </div>
  </ng-template>
</div>
